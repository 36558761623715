import React, { useState, useEffect } from 'react';
import moment from 'moment';
import config from '../../../config/config';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

const Chat = ({ activitiesData, rowData, setActivitiesData }) => {

    let user = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials')) : {};

    useEffect(() => {
        // Start counting time and make an API call every minute
        const interval = setInterval(() => {
            getRecentComments();
        }, 60000);

        // Cleanup function to stop the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    const getRecentComments = () => {
        let latestId=''
        setActivitiesData(data=>{
            latestId = data[0]?._id
            return data;
        })
        let body = {
            "ticketId": rowData._id,
            "contextType": "TICKETS",
            "lastCommentId": latestId
        }
        return fetchMethodRequest("POST", "notes/getLatestComments", body)
            .then(async (response) => {
                let sessionexpired = localStorage.getItem('sessionexpired');
                if (sessionexpired === "true") {
                    setSessionExpiryModal(true);
                }
                if (response && response.respCode) {
                    if (response.comments && response.comments.length > 0) {
                        setActivitiesData(pre => [...response.comments,...pre]);
                    }
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    const dateAndTimeFormat = (timestamp) => {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        let period = 'AM';

        if (hours >= 12) {
            period = 'PM';

            // Convert 12-hour format to 12 AM/PM format
            if (hours > 12) {
                hours -= 12;
            }
        }
        // Format the time as "hh:mm AM/PM"
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
        let newDate = moment.utc(new Date(timestamp)).format(config.dateMonthFormat);

        return <span title={formattedTime}>
            {newDate + ' ' + formattedTime}
        </span>;
    }

    return (
        <div className="container mt-2 p-2">
            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-primary">
                        <div className="panel-body">
                            <ul className="chat" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                {activitiesData?.length > 0 && activitiesData.map((message, index) => {
                                    const isSentByCurrentUser = message.createdByName === user.name;
                                    return (
                                        <li
                                            key={index}
                                            className={`clearfix mb-2 d-flex ${isSentByCurrentUser ? 'justify-content-end' : 'justify-content-start'}`}
                                        >
                                            <div
                                                className={`chat-body clearfix mt-1`}
                                                style={{
                                                    backgroundColor: isSentByCurrentUser ? '#dcf8c6' : '#f1f1f1',
                                                }}
                                            >
                                                <p>{message.notes || message.comments}</p>
                                                <div className={`header ${isSentByCurrentUser ? 'text-end' : ''} `}>
                                                    <small className="primary-font me-2 fw-semibold">{message.createdByName}</small>
                                                    <small className="text-muted">
                                                        {dateAndTimeFormat(message.created)}
                                                    </small>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
