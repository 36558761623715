const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "http://mlstestapi.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "http://mlstestapi.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "http://mlstestapi.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "http://mlstestapi.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "http://mlstestapi.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "http://mlstestapi.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  myproperties:
    "http://mlstestapi.dosystemsinc.com/images/sample_myproperties_bulk_upload_file.csv",
  clients:
    "http://mlstestapi.dosystemsinc.com/images/sample_clients_bulk_upload_file.csv",
  searches:
    "http://mlstestapi.dosystemsinc.com/images/sample_searches_bulk_upload_file.csv",
  savedsearches:
    "http://mlstestapi.dosystemsinc.com/images/sample_savedsearches_bulk_upload_file.csv",
  offices:
    "http://mlstestapi.dosystemsinc.com/images/sample_offices_bulk_upload_file.csv",
  agents:
    "http://mlstestapi.dosystemsinc.com/images/sample_agents_bulk_upload_file.csv",
  tickets:
    "http://mlstestapi.dosystemsinc.com/images/sample_tickets_bulk_upload_file.csv",
  recentmatchedproperties:
    "http://mlstestapi.dosystemsinc.com/images/sample_recentmatchedproperties_bulk_upload_file.csv",
  notes:
    "http://mlstestapi.dosystemsinc.com/images/sample_notes_bulk_upload_file.csv",
  matchedproperties:
    "http://mlstestapi.dosystemsinc.com/images/sample_matchedproperties_bulk_upload_file.csv",
  reminders:
    "http://mlstestapi.dosystemsinc.com/images/sample_reminders_bulk_upload_file.csv",
};
export default configSampleFiles;
